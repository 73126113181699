import { render, staticRenderFns } from "./AdendasEnmiendasComponent.vue?vue&type=template&id=7dc53fea"
import script from "./AdendasEnmiendasComponent.vue?vue&type=script&lang=js"
export * from "./AdendasEnmiendasComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports